<template>
  <div class="list-filters d-flex flex-column" style="width: 100%">
    <div class="px-3 py-2 d-flex flex-wrap">
      <div class="list-filters__left-slot py-2 d-flex">
        <v-btn @click="expand = !expand" rounded outlined>
          <v-icon> ${{ "Icon16Sliders" }} </v-icon>
          <span class="ml-2">Näytä rajaukset</span>
        </v-btn>

        <v-btn class="mr-4" text>
          <v-icon> ${{ "Icon16Reset" }} </v-icon>
          <span class="ml-2" @click="clearFilters()">Tyhjennä</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div class="list-filters__right-slot py-2">
        <v-select
          style="width: 240px"
          :items="items"
          hide-details
          flat
          outlined
          label="Järjestä"
          dense
          v-model="sort"
          @change="sortValues(sort)"
          class="order-select"
        >
        </v-select>
      </div>
    </div>

    <div>
      <v-expand-transition>
        <div v-show="expand" class="px-3">
          <v-divider></v-divider>

          <div class="d-flex flex-column flex-wrap">
            <div class="py-4 d-flex flex-column">
              <label class="caption">Hinta</label>
              <v-range-slider
                style="width: 100%"
                v-model="range"
                :max="rangeMax"
                :min="rangeMin"
                hide-details
                class="align-center cost-range"
                @change="testChange"
              >
                <template v-slot:prepend>
                  <v-text-field
                    :value="range[0]"
                    class="mt-0 pt-0 test"
                    hide-details
                    single-line
                    outlined
                    dense
                    type="number"
                    style="width: 100px"
                    @change="testChange"
                  >
                    <template v-slot:append>
                      <div class="text--secondary line-height-2 my-1">€</div>
                    </template>
                  </v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="range[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    outlined
                    dense
                    type="number"
                    style="width: 100px"
                    @change="testChange"
                  >
                    <template v-slot:append>
                      <div class="text--secondary line-height-2 my-1">€</div>
                    </template>
                  </v-text-field>
                </template>
              </v-range-slider>
            </div>
          </div>
          <div style="width: 100%">
            <template v-for="(item, i) in filteredList">
              <v-btn
                style="margin-right: 0.5rem; margin-bottom: 0.5rem"
                :key="i"
                outlined
                rounded
                text
                @click="selectFilter(item.uuid, i)"
                :disabled="item.disabled"
              >
                {{ item.name.fi }}
              </v-btn>
            </template>
          </div>
          <!-- <v-row>
            <template v-for="(item, name) in filterDetailsValue">
              <v-col cols="2" :key="name">
                <v-select
                  :items="item"
                  :label="name"
                  v-model="selectedFilter[name]"
                ></v-select>
              </v-col>
            </template>
          </v-row> -->
          <v-divider></v-divider>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    minValue: {
      required: false,
      type: Number,
    },
    maxValue: {
      required: false,
      type: Number,
    },
    filteredList: {
      required: false,
      type: Array,
    },
    filterDetailsValue: {
      required: false,
      type: Object,
    },

    // minMaxValues: {
    //   required: true,
    //   type: Object,
    // },
  },
  data: function() {
    return {
      items: [
        /*{ text: "Ostetuin ensin", disabled: true },*/
        "Uusin ensin",
        "Vanhin ensin",
        "Halvin ensin",
        "Kallein ensin",
        /*{ text: "Uusin ensin", disabled: true },*/
      ],
      expand: false,
      rangeMin: 0,
      rangeMax: this.maxValue,
      range: [this.minValue, this.maxValue],
      model: null,
      sort: "",
      timeout: null,
      selectedFilter: {},
    };
  },
  methods: {
    testChange() {
      // console.log(this.range);
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        // console.log(this.range);
        this.addValues(this.range);
      }, 2000);
    },
    addValues(item) {
      this.$emit("searchValues", item);
    },
    sortValues(value) {
      this.$emit("sortResult", value);
    },
    selectFilter(uuid, i) {
      this.$emit("selectFilter", uuid, i);
    },
    clearFilters() {
      this.sort = "";
      this.$emit("clearFilters");
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .list-filters > .d-flex.flex-wrap {
    flex-direction: column-reverse;
  }

  .list-filters .order-select {
    width: 100% !important;
  }

  .list-filters__right-slot,
  .list-filters__left-slot {
    width: 100%;
  }
}
</style>
<style>
@media screen and (max-width: 600px) {
  .cost-range {
    display: flex;
    flex-wrap: wrap;
  }

  .cost-range .v-input__prepend-outer {
    order: 1;
  }

  .cost-range .v-input__append-outer {
    order: 2;
    margin-left: auto;
  }

  .cost-range .v-input__control {
    order: 3;
  }
}
</style>
