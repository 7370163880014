<template>
  <div
    class="product-search flex-1 overflow-hidden px-md-6 pb-8"
    style="width: 100%"
  >
    <div class="list-actions py-4 px-3 d-flex">
      <div class="d-flex list-header align-baseline">
        <h2>
          Tulokset haulle: <span> {{ this.searched }} </span>
        </h2>
        <div class="ml-4">tuloksia {{ this.items.length }}</div>
      </div>
      <v-spacer></v-spacer>
      <v-btn-toggle v-model="toggle_exclusive" mandatory>
        <v-btn @click="makeList('grid')">
          <v-icon>${{ "Icon24Grid" }}</v-icon>
        </v-btn>

        <v-btn @click="makeList('list')">
          <v-icon>${{ "Icon24List" }}</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>

    <ListFiltersSearch
      v-if="this.minValue !== 0 && this.maxValue !== 999999"
      @searchValues="searchValues"
      @sortResult="sortResult"
      :minValue="this.minValue"
      :maxValue="this.maxValue"
      :filteredList="this.filteredList"
      @selectFilter="selectFilter"
      @clearFilters="clearFilters"
      :filterDetailsValue="this.filterDetailsValue"
    />

    <!-- <template v-for="(item, i) in filteredList">
      <v-btn
        :key="i"
        outlined
        @click="selectFilter(item.uuid, i)"
        :disabled="item.disabled"
      >
        {{ item.name.fi }}
      </v-btn>
    </template> -->

    <!-- <v-row>
      <template v-for="(item, name) in filterDetailsValue">
        <v-col cols="2" :key="name">
          <v-select
            :items="item"
            :label="name"
            v-model="selectedFilter[name]"
          ></v-select>
        </v-col>
      </template>
    </v-row> -->

    <div
      v-if="!this.filterSelected"
      class="list-container d-flex flex-wrap mx-auto"
      :class="list"
    >
      <template v-for="item in items">
        <ProductCard :product="item" :key="item.uuid" />
      </template>
    </div>
    <div
      v-if="this.filterSelected"
      class="list-container d-flex flex-wrap mx-auto"
      :class="list"
    >
      <template v-for="item in filterItems">
        <ProductCard :product="item" :key="item.uuid" />
      </template>
    </div>

    <RecentItems />
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard";
import RecentItems from "@/components/RecentItems";
import ListFiltersSearch from "@/components/ListFiltersSearch";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  components: { ProductCard, RecentItems, ListFiltersSearch },
  props: {
    search: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      toggle_exclusive: undefined,
      list: "",
      items: "",
      searched: this.$route.params.value,
      filter: "",
      filteredList: [],
      filterSelected: false,
      selectedFilter: {},
      searchValue: "",
      minValue: 0,
      maxValue: 999999,
      filterDetails: new Set(),
      filterDetailsValue: {},
      searchData: {},
      url: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/product",
      filterItems: "",
      searchRange: [0, 999999],
      selectedUUID: "",
      url2: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/tree",
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapGetters(["categoryName"]),
  },
  methods: {
    clearFilters() {
      // console.log("clear all");
      this.filterSelected = false;
      this.getData();
    },
    async getData() {
      let tempStr = this.$route.params.value.split(/(\s+)/);
      let tempStr2 = tempStr.filter(function(entry) {
        return entry.trim() != "";
      });

      let value = "";

      if (tempStr2.length > 1) {
        tempStr2.map((e, i) => {
          value += "*" + e + "*";
          if (tempStr2.length > i + 1) {
            value += " AND ";
          }
        });
      } else {
        value = "*" + this.$route.params.value + "*";
      }

      let data = {
        from: 0,
        size: 10000,
        query: {
          query_string: {
            fields: ["name^4", "description^1"],
            query: value,
          },
        },
      };

      this.searchValue = value;

      await axios
        .post(this.url, data)
        .then((response) => {
          // console.log(response.data.items);
          this.items = response.data.items;
          this.filterTest(response.data.items);
          this.minValue = Math.min.apply(
            Math,
            this.items.map((e) => {
              if (e.details.price) {
                return e.details.price.value;
              } else return 0;
            })
          );
          this.maxValue = Math.max.apply(
            Math,
            this.items.map((e) => {
              if (e.details.price) {
                return e.details.price.value;
              } else return 9999;
            })
          );
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    filterData() {
      let e = this.searchRange;
      this.searchData = {
        from: 0,
        size: 100,
        query: {
          bool: {
            must: {
              query_string: {
                fields: ["name^4", "description^1"],
                query: this.searchValue,
              },
            },
            filter: [
              {
                query_string: {
                  fields: ["categories"],
                  query: this.selectedUUID,
                },
              },
              {
                range: {
                  "details.price.value": {
                    from: e[0],
                    to: e[1],
                  },
                },
              },
            ],
          },
        },
      };
    },

    selectFilter(uuid, number) {
      this.selectedUUID = uuid;
      this.filterData();

      // need to fix this
      /***/
      if (!this.filterSelected) {
        this.filteredList.map((e, i) => {
          this.$set(this.filteredList[i], "disabled", true);
          this.$set(this.filteredList[number], "disabled", false);
        });
      } else {
        this.filteredList.map((e, i) => {
          this.$set(this.filteredList[i], "disabled", false);
          // console.log("clear filter");
        });
      }
      /***/

      this.filterSelected = !this.filterSelected;
      if (this.filterSelected) {
        this.axios.post(this.url, this.searchData).then((res) => {
          // console.log(res.data);
          this.filterItems = res.data.items;
        });
      }
    },
    removeUnusedFilter(item) {
      if (item.match(/wp_id|price|tax|sku|in_stock/)) {
        return false;
      }
      return true;
    },
    async filterTest(items) {
      let list = new Set();

      items.map((e) => {
        if (e.categories) {
          e.categories.map((x) => {
            list.add(this.categoryName(x));
          });
        }

        for (let z in e.details) {
          if (this.removeUnusedFilter(z)) {
            this.filterDetails.add(z);
          }
        }
      });
      let list2 = Array.from(list);

      list2.map((e, i) => {
        this.$set(list2[i], "disabled", false);
      });

      // this.filteredList = list2;
      // console.log(list2);

      let data = {
        from: 0,
        size: 100,
        query: {
          bool: {
            must: {
              match_phrase: {
                "tree.children.name": this.$store.state.cms.data.brands.uuid,
              },
            },
          },
        },
        _source: ["tree.children"],
      };

      let list3 = new Set();
      await this.axios.post(this.url2, data).then((res) => {
        res.data.items[0].tree.children.map((res) => {
          if (res.name === "Brändit-sivu") {
            res.children.map((r) => {
              list3.add(r.name);
            });
          } else if (res.name === "main-menu") {
            res.children.map((r) => {
              r.children.map((x) => {
                list3.add(x.name);
              });
            });
          }
        });
      });
      let list5 = Array.from(list3);
      let list6 = list2.filter((item) => list5.includes(item.name.fi));

      this.filteredList = list6;

      this.filterDetails.forEach((e) => {
        // console.log(e);
        let test = this.findVariant(this.items, e);
        let temp = new Set();
        test.map((x) => {
          if (x[e]) {
            temp.add(x[e].value);
          }
        });
        let temp2 = Array.from(temp);
        this.$set(this.filterDetailsValue, e, temp2);
      });
      // console.log(this.filterDetailsValue);
    },
    makeList: function(option) {
      if (option === "grid") {
        this.list = "";
      } else if (option === "list") {
        this.list = "card-list";
      }
    },

    findVariant: function(variantsToFind, keyToMatch) {
      return this.helper(variantsToFind, keyToMatch, []);
    },

    helper: function(object, key, result) {
      if (!object) return result;
      if (object instanceof Array) {
        for (var i in object) {
          result = result.concat(this.helper(object[i], key, []));
        }
        return result;
      }
      if (object[key]) result.push(object);

      if (typeof object == "object" && object !== null) {
        let children = Object.keys(object);
        if (children.length > 0) {
          for (i = 0; i < children.length; i++) {
            result = result.concat(this.helper(object[children[i]], key, []));
          }
        }
      }
      return result;
    },

    searchValues(e) {
      this.searchRange = e;
      // console.log(e);
      this.filterData();
      this.filter = e;
      // let url = process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/product";
      let data2 = {};
      let data = {
        from: 0,
        size: 1000,
        query: {
          bool: {
            must: {
              query_string: {
                fields: ["name^4", "description^1"],
                query: this.searchValue,
              },
            },
            filter: {
              range: {
                "details.price.value": {
                  from: e[0],
                  to: e[1],
                },
              },
            },
          },
        },
      };
      if (!this.filterSelected) {
        data2 = data;
      } else {
        // console.log("searchData");
        data2 = this.searchData;
      }
      axios
        .post(this.url, data2)
        .then((response) => {
          if (!this.filterSelected) {
            this.items = response.data.items;
          } else {
            this.filterItems = response.data.items;
            // console.log(response.data.items);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    sortResult(e) {
      console.log(e);
      if (e === "Halvin ensin") {
        if (this.filterSelected) {
          this.filterItems.sort(
            (a, b) => a.details.price.value - b.details.price.value
          );
        } else {
          this.items.sort(
            (a, b) => a.details.price.value - b.details.price.value
          );
        }
      } else if (e === "Kallein ensin") {
        if (this.filterSelected) {
          this.filterItems.sort(
            (a, b) => b.details.price.value - a.details.price.value
          );
        } else {
          this.items.sort(
            (a, b) => b.details.price.value - a.details.price.value
          );
        }
      } else if (e === "Uusin ensin") {
        if (this.filterSelected) {
          this.filterItems.sort((a, b) => {
            let c = a.created.split(" ");
            let d = b.created.split(" ");
            return new Date(d[0]) - new Date(c[0]);
          });
        } else {
          this.items.sort((a, b) => {
            let c = a.created.split(" ");
            let d = b.created.split(" ");
            return new Date(d[0]) - new Date(c[0]);
          });
        }
      } else if (e === "Vanhin ensin") {
        if (this.filterSelected) {
          this.filterItems.sort((a, b) => {
            let c = a.created.split(" ");
            let d = b.created.split(" ");
            return new Date(c[0]) - new Date(d[0]);
          });
        } else {
          this.items.sort((a, b) => {
            let c = a.created.split(" ");
            let d = b.created.split(" ");
            return new Date(c[0]) - new Date(d[0]);
          });
        }
      }

      // this.items.map((e) => {
      //   console.log(e.details.price.value + ", ");
      // });
    },
  },
  watch: {
    filterSelected: function(e) {
      if (e) {
        this.filterItems = "";
      } else {
        console.log("filter has been selected");
      }
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .list-header {
    flex-direction: column;
  }

  .list-actions {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .list-header h2 {
    font-size: 1rem;
    font-family: Roboto, sans-serif;
  }

  .list-header h2 {
    font-size: 0.75rem;
    font-family: Roboto, sans-serif;
    line-height: 1.25rem;
  }

  .list-header h2 span {
    font-size: 1rem;
    display: block;
    line-height: 1.25rem;
    font-weight: 600;
  }

  .list-header h2 + div {
    margin-left: 0 !important;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}
</style>
